import React, {useState, useEffect} from 'react';
import {NavLink as RouterLink, useLocation, NavLinkProps} from 'react-router-dom';
import {alpha, styled} from '@mui/material/styles';
import {
    Box,
    List,
    Link,
    Drawer,
    Collapse,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    ListItemButtonProps, Stack, Typography,
} from '@mui/material';

import {NAVBAR} from '../../config';

import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import {IconButtonAnimate} from '../../components/animate';
import {NavSectionVertical} from '../../components/nav-section';

import {MenuProps, MenuItemProps} from './type';

interface RouterLinkProps extends ListItemButtonProps {
    component?: React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>;
    to?: string;
    end?: boolean;
}

const ListItemStyle = styled(ListItemButton)<RouterLinkProps>(({theme}) => ({
    ...theme.typography.body2,
    textTransform: 'capitalize',
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    color: theme.palette.text.secondary,
}));

export default function MenuMobile({navConfig}: MenuProps) {
    const {pathname} = useLocation();

    const [open, setOpen] = useState(false);

    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        if (drawerOpen) {
            handleDrawerClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <>

            <IconButtonAnimate
                onClick={handleDrawerOpen}
                sx={{
                    ml: 1,
                    color: 'text.primary',
                }}
            >
                <Iconify icon={'eva:menu-2-fill'}/>
            </IconButtonAnimate>

            <Drawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{keepMounted: true}}
                PaperProps={{sx: {pb: 5, width: 260}}}
            >
                <Scrollbar>

                    <Stack direction='row' spacing={0.5} alignItems='center'>
                        <Logo sx={{mx: 2.5, my: 3}}/>
                        <Typography variant="h6" sx={{
                            color: 'text.secondary',

                            textDecoration: 'none',
                        }}>
                            CodeMakerz
                        </Typography>
                    </Stack>
                    <List disablePadding>
                        {navConfig.map((link) => (
                            <MenuMobileItem key={link.title} item={link} isOpen={open} onOpen={handleOpen}/>
                        ))}
                    </List>
                </Scrollbar>
            </Drawer>
        </>
    );
}

type MenuMobileItemProps = {
    item: MenuItemProps;
    isOpen: boolean;
    onOpen: VoidFunction;
};

function MenuMobileItem({item, isOpen, onOpen}: MenuMobileItemProps) {
    const {title, path, icon, children} = item;

    if (children) {
        return (
            <>
                <ListItemStyle onClick={onOpen}>
                    <ListItemIcon>{icon}</ListItemIcon>

                    <ListItemText disableTypography primary={title}
                    />
                    <Iconify
                        icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                        sx={{width: 16, height: 16, ml: 1}}
                    />
                </ListItemStyle>

                <Collapse in={isOpen} unmountOnExit>
                    <Box sx={{display: 'flex', flexDirection: 'column-reverse'}}>
                        <NavSectionVertical
                            navConfig={children}
                            sx={{
                                '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                                    height: 200,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    bgcolor: 'background.neutral',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                                    '& > *:not(.MuiTouchRipple-root)': {display: 'none'},
                                },
                            }}
                        />
                    </Box>
                </Collapse>
            </>
        );
    }

    if (title === 'Documentation') {
        return (
            <Link href={path} target='_blank' rel='noopener' underline='none'>
                <ListItemStyle>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText disableTypography primary={title}/>
                </ListItemStyle>
            </Link>
        );
    }

    return (
        <ListItemStyle
            to={path}
            component={RouterLink}
            end={path === '/'}
            sx={{
                '&.active': {
                    color: 'primary.main',
                    fontWeight: 'fontWeightMedium',
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            }}
        >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText disableTypography primary={title}/>
        </ListItemStyle>
    );
}
