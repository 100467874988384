import {Link as RouterLink} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Grid, Link, Divider, Container, Typography, Stack} from '@mui/material';
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';

const LINKS = [

    {
        headline: 'Yasal',
        children: [
            {name: 'KVKK ve Gizlilik Politikası', href: '/privacy'},
        ],
    }
];

const RootStyle = styled('div')(({theme}) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
}));


export default function MainFooter() {
    return (
        <RootStyle>
            <Divider/>

            <Container sx={{pt: 10}}>
                <Grid
                    container justifyContent={{xs: 'center', md: 'space-between'}}
                    sx={{textAlign: {xs: 'center', md: 'left'}}}
                >
                    <Grid item xs={12} sx={{mb: 3}}>

                        <Stack
                            direction='row'
                            spacing={2}
                            alignItems={"center"}
                            justifyContent={{xs: 'center', md: 'flex-start'}}

                        >
                            <Logo sx={{xs: 'auto', md: 'inherit'}}/>
                            <Typography variant="h4" sx={{
                                color: 'text.primary',
                                mr: 2,
                            }}>
                                CodeMakerz
                            </Typography>
                        </Stack>

                    </Grid>


                    <Grid item xs={8} md={3}>
                        <Typography variant='body2' sx={{
                            pr: {md: 5},
                            textAlign: {xs: 'center', md: 'left'},
                        }}>
                            CodeMakerz, Yeniliğin Ayak İzi !
                            <br/>
                            <br/>
                            Yeniliği, güvenle müşterilerine sunan, müşteri memnuniyeti odağında uygulamarını geliştiren
                            firma
                        </Typography>

                        <Stack
                            direction='row'
                            justifyContent={{xs: 'center', md: 'flex-start'}}
                            sx={{mt: 5, mb: {xs: 5, md: 0}}}
                        >
                            <SocialsButton sx={{mx: 0.5}}/>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={7}>
                        <Stack
                            spacing={5}
                            direction={{xs: 'column', md: 'row'}}
                            justifyContent='space-between'
                        >
                            {LINKS.map((list) => (
                                <Stack key={list.headline} spacing={2} sx={{pr: {md: 6}}}>
                                    <Typography component='p' variant='overline'>
                                        {list.headline}
                                    </Typography>

                                    {list.children.map((link) => (

                                        <Link
                                            to={link.href}
                                            key={link.name}
                                            color='inherit'
                                            variant='body2'
                                            component={RouterLink}
                                            sx={{display: 'block'}}
                                        >
                                            {link.name}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                            <Stack key='qwerrsdfsd' spacing={2}>
                                <Typography component='p' variant='overline'>
                                    İLETİŞİM
                                </Typography>
                                <Typography variant='body2' color='inherit'>
                                    destek@codemakerz.com
                                </Typography>
                                <Typography variant='body2' color='inherit'>
                                    Meşruti̇yet Mah. Konur Sk. Özsoy İş Hanı Blok No: 25 İç Kapı No: 13 Çankaya/ANKARA
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

                <Typography
                    component='p'
                    variant='body2'
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: {xs: 'center', md: 'left'},
                    }}
                >
                    © 2022 CodeMakerz. Tüm hakları saklıdır.
                </Typography>
            </Container>
        </RootStyle>
    );
}
