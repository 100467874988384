import {useLocation, Outlet} from 'react-router-dom';
import {Box, Link, Container, Typography, Stack, Fade, useScrollTrigger, Fab} from '@mui/material';
import Logo from '../../components/Logo';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React from "react";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    children: React.ReactElement;
}

function ScrollTop(props: Props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{position: 'fixed', bottom: 16, right: 16}}
            >
                {children}
            </Box>
        </Fade>
    );
}

export default function MainLayout() {
    const {pathname} = useLocation();

    const isHome = pathname === '/';

    return (
        <Stack sx={{minHeight: 1}} translate={"no"}>
            <MainHeader/>
            <div id="back-to-top-anchor"/>
            <Outlet/>

            <Box sx={{flexGrow: 1}}/>

            {!isHome ? (
                <MainFooter/>
            ) : (
                <Box
                    sx={{
                        py: 5,
                        textAlign: 'center',
                        position: 'relative',
                        bgcolor: 'background.default',
                    }}
                >
                    <Container>
                        <Logo sx={{mb: 1, mx: 'auto'}}/>

                        <Typography variant='caption' component='p'>
                            © Tüm hakları saklıdır.
                            <br/> <Link href='/'>CodeMakerz</Link> ürünüdür.
                        </Typography>
                    </Container>
                </Box>
            )}
            <ScrollTop>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon/>
                </Fab>
            </ScrollTop>
        </Stack>
    );
}
