import { alpha } from '@mui/material/styles';
import {
  Link,
  Stack,
  Button,
  Tooltip,
  IconButton,
  ButtonProps,
  IconButtonProps, SvgIcon,
} from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

export type SocialLinks = {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
};

type IProps = IconButtonProps & ButtonProps;

interface Props extends IProps {
  simple?: boolean;
  initialColor?: boolean;
  links?: SocialLinks;
}

export default function SocialsButton({
                                        initialColor = false,
                                        simple = true,
                                        links = {},
                                        sx,
                                        ...other
                                      }: Props) {
  const SOCIALS = [
    {
      name: 'Facebook',
      icon: FacebookIcon,
      socialColor: '#1877F2',
      path: links.facebook || 'https://www.facebook.com/profile.php?id=100084158467414',
    },
    {
      name: 'Instagram',
      icon: InstagramIcon,
      socialColor: '#E02D69',
      path: links.instagram || 'https://www.instagram.com/thecodemakerz/',
    },
    {
      name: 'Linkedin',
      icon: LinkedInIcon,
      socialColor: '#007EBB',
      path: links.linkedin || 'https://www.linkedin.com/company/86391961',
    },
    {
      name: 'Twitter',
      icon: TwitterIcon,
      socialColor: '#00AAEC',
      path: links.twitter || 'https://twitter.com/codemakerz',
    },
  ];

  return (
    <Stack direction='row' flexWrap='wrap' alignItems='center'>
      {SOCIALS.map((social) => {
        const { name, icon, path, socialColor } = social;
        return simple ? (
          <Link key={name} href={path}>
            <Tooltip title={name} placement='top'>
              <IconButton
                color='inherit'
                sx={{
                  ...(initialColor && {
                    color: socialColor,
                    '&:hover': {
                      bgcolor: alpha(socialColor, 0.08),
                    },
                  }),
                  ...sx,
                }}
                {...other}
              >
                <SvgIcon component={icon} sx={{ fontSize: 20 }} />
              </IconButton>
            </Tooltip>
          </Link>
        ) : (
          <Button
            key={name}
            href={path}
            color='inherit'
            variant='outlined'
            size='small'
            startIcon={<SvgIcon component={icon} />}
            sx={{
              m: 0.5,
              flexShrink: 0,
              ...(initialColor && {
                color: socialColor,
                borderColor: socialColor,
                '&:hover': {
                  borderColor: socialColor,
                  bgcolor: alpha(socialColor, 0.08),
                },
              }),
              ...sx,
            }}
            {...other}
          >
            {name}
          </Button>
        );
      })}
    </Stack>
  );
}
