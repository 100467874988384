// @types
import { BackgroundType } from '../type';


export const varBgColor = (props?: BackgroundType) => {
  const colors = props?.colors || ['#19dcea', '#b22cff'];
  const duration = props?.duration || 5;
  const ease = props?.ease || 'linear';

  return {
    animate: {
      background: colors,
      transition: { duration, ease },
    },
  };
};

