import React from 'react';
import ThemeProvider from "./theme";
import ThemeSettings from "./components/settings";
import Router from "./routes";
import { MotionLazyContainer } from "./components/animate";
import NotistackProvider from "./components/NotistackProvider";

function App() {
    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <NotistackProvider>
                        <Router/>
                    </NotistackProvider>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}

export default App;
