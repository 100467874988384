import { ReactNode, useRef } from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles, Collapse, SvgIcon } from '@mui/material';
import useSettings from '../hooks/useSettings';
// theme
import { ColorSchema } from '../theme/palette';
import { IconButtonAnimate } from './animate';

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import * as React from 'react';

function SnackbarStyles() {
  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
              {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& svg': { width: 20, height: 20 },
          },
        },
      }}
    />
  );
}


type Props = {
  children: ReactNode;
};

export default function NotistackProvider({ children }: Props) {
  const { themeDirection } = useSettings();

  const isRTL = themeDirection === 'rtl';

  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        TransitionComponent={isRTL ? Collapse : undefined}
        variant='success' // Set default variant
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon={InfoIcon} color='info' />,
          success: <SnackbarIcon icon={CheckIcon} color='success' />,
          warning: <SnackbarIcon icon={ErrorIcon} color='warning' />,
          error: <SnackbarIcon icon={WarningIcon} color='error' />,
        }}
        // With close as default
        action={(key) => (
          <IconButtonAnimate size='small' onClick={onClose(key)} sx={{ p: 0.5 }}>
            <CloseIcon />
          </IconButtonAnimate>
        )}
      >
        {children}
      </SnackbarProvider>
    </>
  );
}


type SnackbarIconProps = {
  icon: React.ElementType;
  color: ColorSchema;
};

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component='span'
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <SvgIcon component={icon} sx={{ fontSize: 24 }} />
    </Box>
  );
}
