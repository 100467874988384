import {Link as RouterLink, Outlet} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import Logo from '../components/Logo';
import {Stack, Typography} from "@mui/material";

const HeaderStyle = styled('header')(({theme}) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0)
    }
}));


export default function LogoOnlyLayout() {
    return (
        <>
            <HeaderStyle>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Logo/>
                    <Typography variant="h4" component={RouterLink} to='/' sx={{
                        color: 'text.primary',
                        mr: 2,
                        textDecoration: 'none',

                    }}>
                        CodeMakerz
                    </Typography>
                </Stack>
            </HeaderStyle>
            <Outlet/>
        </>
    );
}
