import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {HelmetProvider} from "react-helmet-async";
import {SettingsProvider} from "./contexts/SettingsContext";
import {CollapseDrawerProvider} from "./contexts/CollapseDrawerContext";
import {BrowserRouter} from "react-router-dom";

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <HelmetProvider>
        <SettingsProvider>
            <CollapseDrawerProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </CollapseDrawerProvider>
        </SettingsProvider>
    </HelmetProvider>
);
